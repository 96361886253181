<template>
  <div class="vz-phone-input" ref="$el">
    <div class="vz-phone-input-wrapper">
      <div class="vz-phone-input-country-selector">
        <vz-select
          ref="countrySelect"
          :model-value="selectedCountry"
          class="select-country"
          :register-to-validate="false"
          :class="{disabled, 'has-error': isShowError}"
          :disabled="disabled"
          :readonly="readonly"
          :items="countries"
          :big="big"
          :large="large"
          list-class="vz-phone-input-country-selector-list"
          @change="onChangeCountry"
        />
      </div>
      <div class="vz-phone-input-control-wrapper">
        <div class="vz-input">
          <slot name="label">
            <vz-label
              class="vz-input-label"
              without-dot
              :variant="disabled ? 'color-inactive' : 'dark-gray'"
              :class="labelPublicStyle ? 'public-style' : ''"
              required
              v-text="label"
            />
          </slot>
          <vz-label
            v-if="additionalButton && !disabled"
            class="vz-label-additinal-label"
            without-dot
            variant="primary"
            @click.stop.prevent="onAdditionalButtonClick"
            v-text="'+ Доп. номер'"
          />
          <div class="vz-input-wrapper">
            <vz-mask
              ref="vzmask"
              class="vz-input-control"
              v-model="phone"
              :class="{big, large, disabled, 'has-error': isShowError}"
              :placeholder="placeholder"
              :mask="maskCountry"
              :disabled="disabled"
              :readonly="readonly"
              @input="onInput"
              @focus="onFocus"
              @change="onChange"
              @paste="onPaste"
            />
          </div>
        </div>
      </div>
    </div>
    <vz-invalid-message v-if="isShowError">
      {{ errorText }}
    </vz-invalid-message>
  </div>
</template>

<script setup lang="ts">
import { PropType } from 'vue'
import { PhoneItem, ValidationRule } from '~/types/types'
import {phoneCountries} from "~/common/objects";
import {getCountryByPhone} from '~/common/functions';
import VzSelect from '~/components/ui-kit/vz-select.vue';
import VzLabel from '~/components/ui-kit/vz-label.vue';
import VzInvalidMessage from '~/components/ui-kit/vz-invalid-message.vue';
import VzMask from '~/components/ui-kit/vz-mask.vue';

const emit = defineEmits([
  'change-country',
  'update:modelValue',
  'paste',
  'additional-button-click',
  'change',
  'focus'
])
const props = defineProps({
  placeholder: {
    type: String,
    default: ''
  },
  large: {
    type: Boolean,
    default: false
  },
  labelPublicStyle: {
    type: Boolean,
    default: false
  },
  modelValue: {
    type: String,
    default: ''
  },
  label: {
    type: String,
    default: ''
  },
  countries: {
    type: Array as PropType<Array<PhoneItem&{tooltip?: string}>>,
    default: phoneCountries.map(p => ({...p, tooltip: p.name}))
  },
  rules: {
    type: Array as PropType<ValidationRule[]>,
    default : () => ([])
  },
  disabled: {
    type: Boolean,
    default: false
  },
  big: {
    type: Boolean,
    default: false
  },
  required: {
    type: Boolean,
    default: false
  },
  readonly: {
    type: Boolean,
    default: false
  },
  additionalButton: {
    type: Boolean,
    default: false
  },
  crutchCountry: {
    type: String,
    default: ''
  },
})
const $el = ref<HTMLElement|null>(null)
const vzmask = ref()
const { isShowError, errorText, doValidate, dispatchFormEvent } = useValidateble()
const selectedItem = ref<PhoneItem>(phoneCountries.find(p => p.value === 'RU') as PhoneItem)
const phone = ref('')
const selectedCountry = ref('')
const isUserInput = ref(false)
const phoneByCountry: Record<string, string> = reactive(phoneCountries.map(p => p.value).reduce((a, v) => ({ ...a, [v]: ''}), {}) )

defineExpose({
  checkError,
  isShowError
})

const maskCountry = computed((): string => {
  return selectedItem.value.mask
})

const placeholder = computed((): string => {
  return maskCountry.value.replace(/#/g, '_')
})

const maskLength = computed((): number => {
  return maskCountry.value.match(/#/g)?.length || 0
})

watch(() => props.crutchCountry, (crutch: string, oldCrutch?: string): void => {
  if (crutch !== oldCrutch) {
    const country = props.countries.find(c => c.value === crutch)
    if (country) {
      onChangeCountry(country)
    }
  }
})

watch(() => props.modelValue, (v: string): void => {
  phone.value = v
  onPhoneChange(v, true)
  checkError()
})

function onPhoneChange(v: string, strict = false) {
  // Зачем проверка?
  if ( typeof v === 'string' ) {
    const country = getCountryByPhone(v, strict)

    if (!v) {
      phone.value = v
      onChange()
    }

    if (country) {
      selectedItem.value = props.countries.find(c => c.value === country) as PhoneItem
      selectedCountry.value = country
      phone.value = v
    } else if (selectedItem.value) {
      checkFullPhone(v)
    }
  }
}

async function checkError(silent: boolean = false) {
  return await doValidate(props.modelValue, props.rules, silent)
}


function onChangeCountry(country: PhoneItem) {
  const clear = selectedCountry.value !== country.value
  if (!phone.value || clear) {
    // phone.value = country.phoneCode
    phoneByCountry[selectedCountry.value] = phone.value
    phone.value = phoneByCountry[country.value] || country.phoneCode
  }

  if (phone.value?.startsWith(`${selectedItem.value.phoneCode}`)) {
    phone.value = country.phoneCode + phone.value.replace(selectedItem.value.phoneCode, '')
  }
  selectedItem.value = country
  selectedCountry.value = country.value
  emit('update:modelValue', phone.value)
  emit('change-country', country)
  dispatchFormEvent($el.value)
  onChange()
}

function onPaste(e: ClipboardEvent): void {
  if(props.readonly) {
    return
  }

  const data = e.clipboardData?.getData('text/plain').replace(/\D+/g, '') || ''
  const $emit = checkFullPhone(data)

  if ($emit) {
    e.preventDefault()
    emit('paste', e)
  }
}

async function onInput(event: InputEvent|any) {
  let value = event.target.value.replace(/\D/g, '')
  // Для России и Казахстана одинаковые маски, для них страну не изменяем
  if (!(['8', '7'].includes(value) && ['RU', 'KZ'].includes(selectedCountry.value))) {
    onPhoneChange(value)
  }
  if( value && value.length < maskLength.value) {
    const country = props.countries.find(c => value?.startsWith(c.phoneCode) || c.phoneCode.startsWith(value))
    if (!country) {
      if (value === '8') {
        value = selectedItem.value.phoneCode + '_'
      } else {
        value = selectedItem.value.phoneCode + value
      }
      if (isUserInput.value) {
        setTimeout(() => {
          const end = vzmask.value.inputControl.value.length
          vzmask.value.inputControl.setSelectionRange(end, end)
        }, 0)
      }
    }
  }

  emit('update:modelValue', value)
  nextTick( () => {
    if (isUserInput.value) {
      checkError()
    }
  })
}

function onChange(): void {
  if(!phone.value) {
    phone.value = selectedItem.value.phoneCode
  }
  emit('change', phone.value)
  nextTick(() => {
    checkError()
  })
}

onBeforeMount(() => {
  if(props.countries.length) {
    selectedItem.value = props.countries[0]
    selectedCountry.value = selectedItem.value.value
  }
})

onMounted(() => {
  const countryCode = props.modelValue
    ? getCountryByPhone(props.modelValue, false)
    : props.crutchCountry
  const country = countryCode
    ? props.countries.find(c => c.value === countryCode)
    : props.countries.find(c => props.modelValue?.startsWith(c.phoneCode))

  if(country) {
    selectedItem.value = country
    selectedCountry.value = country.value
  }
  phone.value = props.modelValue
  if(!phone.value) {
    phone.value = selectedItem.value.phoneCode
  }
})

async function onFocus(e: Event) {
  isUserInput.value = true
  nextTick( () => {
    const end = vzmask.value.inputControl.value.length
    vzmask.value?.inputControl.setSelectionRange(end, end)
    emit('focus', e)
  })
}

function onAdditionalButtonClick(): void {
  if(!props.disabled) {
    emit('additional-button-click')
  }
}

function checkFullPhone(value: string): boolean {
  const newCountry = props.countries.find(c => value?.startsWith(c.phoneCode))
  const oldValue = phone.value
  let $emit = false

  // Если длина нового телефона совпадает с длиной маски, то меняем телефон. Возможна смена страны
  if (value?.length === maskLength.value) {
    phone.value = value
    if (!newCountry) {
      // Длина совпала, а страны - нет. Скорее всего 8 в начале
      phone.value = selectedItem.value.phoneCode + phone.value.substring(selectedItem.value.phoneCode.length)
    }
    $emit = true
  } else if (value?.length === newCountry?.mask?.match(/#/g)?.length) {
    phone.value = value
    // События на смену страны
    $emit = true
  }
  if ($emit) {
    if (newCountry?.value !== selectedCountry.value) {
      phoneByCountry[selectedCountry.value] = oldValue
    }
    emit('update:modelValue', phone.value)
    emit('change', phone.value)
  }

  return $emit
}
</script>
